// RideDetailService.js
import api from "../Provider/api";
import handleError from "../Provider/errorHandler";

const getRides = async () => {
    try {
        const response = await api.get('userRide/v1/');
        return response.data;
    } catch (error) {
        handleError(error);
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);

        } else if (error.request) {
            // The request was made but no response was received
            console.error(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message);
        }
        console.error(error.config);
    }
}
const getFilteredRides = async (date, phone) => {
    try {
        const response = await api.get(`userRide/v1/filter?date=${date}&phone=${phone}`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}
const getRescheduledRides = async () => {
    try {
        const response = await api.get('userRide/v1/rescheduled');
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

const updateRideDateTime = async (rideId, newDate, rescheduleStatus) => {
    try {
        const response = await api.put(`userRide/v1/updateDateTime?rideId=${rideId}&newDate=${newDate}&rescheduleStatus=${rescheduleStatus}`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}


const getRidesBetweenDates = async (startDate, endDate) => {
    try {
        return await api.get(`userRide/v1/csv?startDate=${startDate}&endDate=${endDate}`, { responseType: 'text' });
    } catch (error) {
        handleError(error);
    }
}

const rideDetailService = {
    getRides,
    getFilteredRides,
    getRescheduledRides,
    updateRideDateTime,
    getRidesBetweenDates // Add the new function to the exported object
};

export default rideDetailService;