// Subscription.jsx
import React, { useEffect, useState } from 'react';
import {Typography, useTheme, Box, Button} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import subscriptionDetailService from '../../Services/SubscriptionDetailService';
import './Subscription.css';
import Swal from "sweetalert2";

function Subscription() {
    const theme = useTheme();
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        subscriptionDetailService.getSubscriptions().then(data => {
            if (data) {
                console.log(data)
                const mappedData = data.map(subscription => ({
                    ...subscription,
                    customerName: subscription.Customer?.name, // Accessing nested property and assigning it to a top-level property
                }));
                setSubscriptions(mappedData);
            } else {
                console.error('No data returned from getSubscriptions');
            }
        }).catch(error => {
            console.error('Error fetching subscriptions:', error);
        });
    }, []);

    const handlePaymentStatusChange = async (params) => {
        let paymentStatus = params.row.paymentStatus;
        let newPaymentStatus;
        if(paymentStatus==='true'){
            newPaymentStatus = false;
        }
        else{
            newPaymentStatus = true;
        }
        console.log({params} , Boolean(params.row.paymentStatus), {newPaymentStatus})
        console.log({params} , {paymentStatus}, {newPaymentStatus})

        const result = await Swal.fire({
            title: 'Confirm Payment Status Change',
            text: `Do you want to change the payment status to ${newPaymentStatus ? 'True' : 'False'}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, change it!',
            cancelButtonText: 'No, keep it'
        });

        if (result.isConfirmed) {
            const response = await subscriptionDetailService.updatePaymentStatus(params.row.id, newPaymentStatus);
            if (response) {
                const updatedSubscriptions = subscriptions.map(subscription => {
                    if (subscription.id === params.row.id) {
                        return {
                            ...subscription,
                            paymentStatus: newPaymentStatus, // Set the paymentStatus to the new value
                        };
                    }
                    return subscription;
                });
                setSubscriptions(updatedSubscriptions);
            }
        }
    }

    return (
        <div className="subscription-container">
            <Box className="subscriptions" style={{ backgroundColor: theme.palette.background.default }}>
                <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
                    User Subscriptions
                </Typography>
                <Box className="dataGridContainer" style={{ backgroundColor: theme.palette.background.paper }}>
                    <DataGrid
                        rows={subscriptions}
                        columns={[
                            { field: 'id', headerName: 'Subscription ID', width: 120 },
                            { field: 'userId', headerName: 'Cust ID', width: 70 },
                            { field: 'customerName', headerName: 'Customer Name', width: 150 }, // Use the new top-level property
                            //{ field: 'subscriptionPlan', headerName: 'Subscription Plan', width: 150 },
                            { field: 'subscriptionCost', headerName: 'Subscription Cost', width: 170 },
                            { field: 'paymentStatus', headerName: 'Payment Status', width: 120 },
                            //{ field: 'subscriptionStatus', headerName: 'Subscription Status', width: 150 },
                            {
                                field: 'createdAt',
                                headerName: 'Created At',
                                width: 170,
                                valueGetter: (params) => {
                                    const [date,time] = params.value.split('T');
                                    const [year,month,day] = date.split('-');
                                    const [hour,minute] = time.split(':');
                                    return `${day}/${month}/${year} ${hour}:${minute}`;
                                },
                            },
                            {
                                field: 'setPaymentStatus',
                                headerName: 'Set Payment Status',
                                sortable: false,
                                width: 200,
                                renderCell: (params) => (
                                    <Button
                                        variant="contained"
                                        onClick={() => handlePaymentStatusChange(params)}
                                    >
                                        Change Status
                                    </Button>
                                ),
                            }
                        ]}
                        pageSize={5}
                    />
                </Box>
            </Box>
        </div>
    );
}

export default Subscription;
