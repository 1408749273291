// CustomerService.js
import api from "../Provider/api";
import handleError from "../Provider/errorHandler";

const getCustomers = async () => {
    try {
        return await api.get(`customer/v1/`);
    } catch (error) {
        handleError(error);
    }
}

const searchCustomers = async (searchTerm) => {
    try {
        return await api.get(`customer/v1/search?term=${searchTerm}`);
    } catch (error) {
        handleError(error);
    }
}
const updateCustomer = async (customer) => {
    try {
        const response = await api.put(`customer/v1?id=${customer.id}`, customer);        return response.data;
    } catch (error) {
        handleError(error);
    }
}

const customerService = {
    getCustomers,
    searchCustomers,
    updateCustomer
};

export default customerService;