import React, {useEffect, useState} from 'react';
import { Button, TextField, Grid, Paper, Typography, Box } from '@mui/material';
import authService from '../../Services/AuthService';
import {useNavigate} from "react-router-dom"; // Import the authService

function LoginPage() {
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
        if (isLoggedIn) {
            navigate('/home');
        }
    }, [navigate]);

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await authService.login(phone, password);
            console.log(response);
            navigate('/home');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="center" style={{ minHeight: '60vh' }}>
            <Grid item xs={12} sm={8} md={6}>
                <Paper style={{ padding: 20, marginTop: 50 }}>
                    <Typography variant="h4" align="center">
                        Login
                    </Typography>
                    <form onSubmit={handleLogin}>
                        <TextField
                            required
                            fullWidth
                            margin="normal"
                            label="Phone"
                            type="tel"
                            variant="outlined"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <TextField
                            required
                            fullWidth
                            margin="normal"
                            label="Password"
                            type="password"
                            variant="outlined"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ marginTop: 20 }}
                        >
                            Login
                        </Button>
                    </form>
                </Paper>
            </Grid>
        </Box>
    );
}

export default LoginPage;