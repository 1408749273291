import React, {useEffect, useState} from 'react';
import {Box, Button, Typography, useTheme} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import rideDetailService from '../../Services/RideDetailService';
import './RescheduledRides.css';
import Swal from "sweetalert2";


function RescheduledRides() {
    const theme = useTheme();
    const [rides, setRides] = useState([]);


    const fetchRescheduledRides = () => {
        rideDetailService.getRescheduledRides().then(data => {
            if (data) {
                console.log(data)
                const mappedData = data.map(ride => ({
                    ...ride,
                    customerName: ride.Customer?.name, // Accessing nested property and assigning it to a top-level property
                }));
                setRides(mappedData);
            } else {
                console.error('No data returned from getRescheduledRides');
            }
        }).catch(error => {
            console.error('Error fetching rescheduled rides:', error);
        });
    };
    useEffect(() => {
        fetchRescheduledRides();
    }, []);

    const handleRescheduleRequest = async (rideId, newDate, rescheduleStatus) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: `You are about to ${rescheduleStatus === 'approved' ? 'Approve' : 'Reject'} the Reschedule Request.`,                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, do it!',
                cancelButtonText: 'No, cancel!'
            });

            if (result.isConfirmed) {
                newDate = newDate.replace(" ","T")+".000Z";
                const response = await rideDetailService.updateRideDateTime(rideId, newDate, rescheduleStatus);
                console.log(response);
                await Swal.fire('Success!', `The Reschedule request has been ${rescheduleStatus}.`, 'success');
                fetchRescheduledRides();
            } else if (result.isDismissed) {
                await Swal.fire('Cancelled', 'The operation was cancelled.', 'error');
            }
        } catch (error) {
            console.error('Error updating ride date time:', error);
        }
    }


    return (
        <div className="user-rides-container">
            <Box className="userRides" style={{ backgroundColor: theme.palette.background.default }}>
                <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
                    Reschedule Rides
                </Typography>
                <Box className="dataGridContainer" style={{ backgroundColor: theme.palette.background.paper }}>
                    <DataGrid
                        rows={rides}
                        columns={[
                            { field: 'id', headerName: 'Ride ID', width: 80 },
                            // { field: 'userId', headerName: 'User ID', width: 70 },
                            { field: 'customerName', headerName: 'Cust Name', width:150},
                            { field: 'driverPhone', headerName: 'Driver Phone', width: 150 },
                            { field: 'pickupAddress', headerName: 'Pickup Address', width: 150 },
                            // { field: 'pickupAddressType', headerName: 'Pickup Address Type', width: 80 },
                            // { field: 'dropAddressType', headerName: 'Drop Address Type', width: 80 },
                            { field: 'dropAddress', headerName: 'Drop Address', width: 150 },
                            {
                                field: 'rideDateTime',
                                headerName: 'Ride Date Time',
                                width: 170,
                                valueGetter: (params) => {
                                    const [date,time] = params.value.split('T');
                                    const [year,month,day] = date.split('-');
                                    const [hour,minute] = time.split(':');
                                    return `${day}/${month}/${year} ${hour}:${minute}`;
                                },
                            },
                            // { field: 'rideStatus', headerName: 'Ride Status', width: 100 },
                            {
                                field: 'additionalRideDetails',
                                headerName: 'Requested Date Time',
                                width: 200,
                                valueGetter: (params) => {
                                    const [date,time] = params.value.split(' ');
                                    const [year,month,day] = date.split('-');
                                    const [hour,minute] = time.split(':');
                                    return `${day}/${month}/${year} ${hour}:${minute}`;
                                },
                            },
                            {
                                field: 'actions',
                                headerName: 'Actions',
                                width: 250,
                                renderCell: (params) => (
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            style={{ marginRight: '10px' }}
                                            onClick={() => handleRescheduleRequest(params.row.id, params.row.additionalRideDetails, 'approved')}
                                        >
                                            Approve
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => handleRescheduleRequest(params.row.id, params.row.additionalRideDetails, 'rejected')}
                                        >
                                            Reject
                                        </Button>
                                    </div>
                                ),
                            },
                        ]}
                        pageSize={5}
                    />
                </Box>
            </Box>

        </div>

    );
}

export default RescheduledRides;
