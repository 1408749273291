import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

export default function TimePickerViewRenderers({ label, size, onTimeChange }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']}>
                <TimePicker
                    label={label}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                    }}
                    minutesStep={5}
                    sx={(theme) => ({
                        width: size,
                        height: size,
                        '& .MuiInputBase-root': {

                        },
                        '& .MuiOutlinedInput-root': {
                            width: '114px',
                            height: '30px',
                        },
                        '& .MuiInputBase-input': {
                        },
                        '& .MuiOutlinedInput-notchedOutline legend': { // Target the legend
                            top: 0, // Adjust the position
                        },
                    })}
                    onChange={onTimeChange}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}