import React, {useEffect, useState} from 'react';
import {AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Button} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

function AppBarComponent({children}) {

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const pages = [
        {name: 'Home', link: '/home'},
        {name: 'Reschedule Rides', link: '/rescheduled-rides'},
        {name: 'User Rides', link: '/user-rides'},
        {name: 'Subscription', link: '/subscription'},
        {name: 'Customer', link: '/customer'},
        // {name: 'Re-Assign Driver', link: '/reassign-driver'},
        // {name: 'Blog', link: '/blog'}
    ];

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('phone');
        localStorage.removeItem('id');
        localStorage.removeItem('name');
        handleMenuClose();
        navigate('/login'); // Redirect to login page
    };
    const [name, setName] = useState('');
    useEffect(() => {
        //get Name is name is stored on local storage or else set it to User
        setName(localStorage.getItem('name') || 'User');
    }, []);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    let isLoggedIn = localStorage.getItem('isLoggedIn');
    console.log(isLoggedIn);

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    MyPickup
                </Typography>
                {isLoggedIn && (
                    <>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 4, textAlign: 'center' }}>
                            Hello {name}
                        </Typography>
                        {pages.map((page, index) => (
                            <Button color="inherit" key={index} onClick={() => navigate(page.link)}>
                                {page.name}
                            </Button>
                        ))}

                        <IconButton color="inherit" onClick={handleMenuOpen}>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem>{children}</MenuItem>
                            <MenuItem onClick={handleLogout}>
                                <IconButton color="inherit">
                                    <LogoutIcon />
                                </IconButton>
                                <Typography variant="body1">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default AppBarComponent;