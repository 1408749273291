import React, { useEffect, useState } from 'react';
import {
    Typography,
    useTheme,
    Box,
    Button,
    Paper,
    Autocomplete,
    TextField,
    Switch,
    FormControlLabel, Backdrop, CircularProgress
} from '@mui/material';
import dayjs from 'dayjs';
import {DataGrid, GridToolbarExport} from '@mui/x-data-grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import rideDetailService from '../../Services/RideDetailService';
import Swal from 'sweetalert2';
import './UserRidesFiltered.css';
import driverService from "../../Services/driverService";
import ReassignDriverDialog from "../../Components/ReassignDriverDialog/ReassignDriverDialog";
import loconavService from "../../Services/loconavService";
function UserRidesFiltered() {
    const theme = useTheme();
    const [rides, setRides] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState(null);
    const [dateValue, setDateValue] = React.useState(null);
    const [backdropOpen, setBackdropOpen] = useState(false);

    const [isGetETAModeEnabled, setIsGetETAModeEnabled] = useState(false);

    const handleETAModeToggleChange = (event) => {
        setIsGetETAModeEnabled(event.target.checked);
    };
    function formatTime(minutes) {
        if (minutes < 60) {
            return `${minutes} Mins`;
        } else if (minutes < 1440) {
            return `${Math.floor(minutes / 60)} Hour${minutes >= 120 ? 's' : ''}`;
        } else {
            return `${Math.floor(minutes / 1440)} Day${minutes >= 2880 ? 's' : ''}`;
        }
    }
    useEffect(() => {
        rideDetailService.getRides().then(data => {
            const ridesWithDriverNames = data.map(ride => {
                const driver = drivers.find(driver => driver.phone === ride.driverPhone);
                return {
                    ...ride,
                    driverName: driver ? driver.name : 'Unknown',
                    customerName: ride.Customer ? ride.Customer.name : 'Unknown', // Add this line
                    customerPhone: ride.Customer ? ride.Customer.phoneNumber : 'Unknown' // Add this line
                };
            });
            setRides(ridesWithDriverNames);
            // console.log(ridesWithDriverNames)
        });
    }, [drivers]);
    useEffect(() => {
        driverService.getDrivers().then(data => {
            setDrivers(data);
        });
    },[]);
    const fetchETA = async (vehicleNumber, pickupLatitude, pickupLongitude) => {
        try {
            let response = await loconavService.getETA(vehicleNumber, pickupLatitude, pickupLongitude);
            response = JSON.parse(response.replace(/\\/g, ''));
            let distance = response.data.elements[0].distance.humanReadable; // Extracting distance
            // Extract numeric value from distance string
            let numericDistance = parseFloat(distance.split(' ')[0]);
            // Multiply by 4, round it and return as ETA
            return Math.round(numericDistance * 4);
        }
        catch (error) {
            console.error('Error:', error);
        }
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setBackdropOpen(true); // Open the backdrop
        let isoDate = null;
        if (dateValue) {
            isoDate = dayjs(dateValue).format(); // Use dayjs to format the date in UTC
            //remove +5:30 from the end of the isoDate
            isoDate = isoDate.slice(0, -6);
            console.log({dateValue},{isoDate})
        }
        const currentDate = dayjs().format('YYYY-MM-DD'); // Get current date and format it
        const selectedDate = dayjs(isoDate).format('YYYY-MM-DD'); // Format the selected date
        const phone = selectedDriver ? selectedDriver.phone : null;
        rideDetailService.getFilteredRides(isoDate, phone).then(async filteredRides => {
            const ridesWithDriverNames = await Promise.all(filteredRides.map(async ride => {
                const driver = drivers.find(driver => driver.phone === ride.driverPhone);

                let eta=0;
                if (isGetETAModeEnabled) {
                    if(ride.rideStatus==='Upcoming'){
                    if (currentDate === selectedDate) { // Compare the dates
                        if (ride.driverPhone) {
                            if (driver) {
                                eta = await fetchETA(driver.vehicleNumber, ride.pickupLatitude, ride.pickupLongitude);
                                console.log(`ETA for ride ${ride.id}: ${eta}`);
                            }
                        }
                    }
                    }
                }

                let calculatedETA=0;
                const rideDateTime = dayjs(ride.rideDateTime);
                const now = dayjs();
                calculatedETA = rideDateTime.diff(now, 'minute');
                return {
                    ...ride,
                    driverETA: eta,
                    calculatedETA: calculatedETA,
                    driverVehicleNumber: driver ? driver.vehicleNumber : 'Unknown',
                    driverName: driver ? driver.name : 'Unknown',
                    customerName: ride.Customer ? ride.Customer.name : 'Unknown', // Add this line
                    customerPhone: ride.Customer ? ride.Customer.phoneNumber : 'Unknown' // Add this line

                };
            }));
            console.log(ridesWithDriverNames); // Log the rides array
            setRides(ridesWithDriverNames);
            setBackdropOpen(false);
        });
    };
    const getRideStatusClassName = (rideStatus) => {
        switch (rideStatus) {
            case 'Completed':
                return 'completed-ride';
            case 'Cancelled':
                return 'cancelled-ride';
            case 'Upcoming':
                return 'upcoming-ride';
            case 'Rescheduled':
                return 'rescheduled-ride';
            default:
                return '';
        }
    };

    const handleDriverChange = (selectedDriver) => {
        setSelectedDriver(selectedDriver);
    };

    const handleCancel = async (rideId) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, cancel it!'
            });

            if (result.isConfirmed) {
                const response = await driverService.cancelRide(rideId);
                console.log(response);
                if (response) {
                    Swal.fire(
                        'Cancelled!',
                        'The ride has been cancelled.',
                        'success'
                    );
                    // Implement any additional logic here, such as updating the UI or handling the response
                }
                else{
                    //throw an error
                    Swal.fire(
                        'Error!',
                        'Failed to cancel ride.',
                        'error'
                    );


                }
            }
        } catch (error) {
            console.error(`Failed to cancel ride with ID: ${rideId}`, error);
            Swal.fire(
                'Error!',
                'Failed to cancel ride.',
                'error'
            );
        }
    };
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedRideId, setSelectedRideId] = useState(null); // New state to hold the selected ride id

    const handleDialogOpen = (id) => { // Modify the function to accept an id parameter
        setSelectedRideId(id); // Set the selected ride id
        setDialogOpen(true);
    };
    const handleDialogClose =()=>
    {
        setDialogOpen(false);
    }

    const handleSubmitReassignDriver = (driverId,rideId) => {
        setDialogOpen(false);
        console.log({driverId},{rideId});
        if (driverId && rideId) {
            console.log({driverId},{rideId});
            driverService.updateRideDriver(rideId, driverId).then(response => {
                console.log(response);
                if (response) {
                    Swal.fire(
                        'Driver Reassigned!',
                        'The driver has been reassigned.',
                        'success'
                    );
                    // Implement any additional logic here, such as updating the UI or handling the response

                }
                else{
                    //throw an error
                    Swal.fire(
                        'Error!',
                        'Failed to reassign driver.',
                        'error'
                    );
                }
            });
        }
    }

    const [isCancelEnabled, setIsCancelEnabled] = useState(true);
    const handleToggleChange = (event) => {
        setIsCancelEnabled(event.target.checked);
    };

    return (
        <div className="user-rides-container">
            <Box className="userRidesFiltered" style={{ backgroundColor: theme.palette.background.default }}>
                <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
                    User Rides
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="center"  >
                    <Paper style={{width: '100%'}}>
                            <form onSubmit={handleFormSubmit} className="form-container">
                                <div className="datepicker-filterRides">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Select Date"
                                            value={dateValue}
                                            onChange={(newValue) => setDateValue(newValue)}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="autocomplete-filterRides">
                                    <Autocomplete
                                        options={drivers} // your options array
                                        getOptionLabel={(option) => option.name+' ID='+option.driverId} // display the driver's name
                                        onChange={(event, value) => handleDriverChange(value)} // handle the selected driver
                                        isOptionEqualToValue={(option, value) => option.driverId === value.driverId}
                                        renderInput={(params) => <TextField {...params} label="Drivers" variant="outlined"/>}
                                    />
                                </div>

                                <div className="submit-button">
                                    <Button type="submit" variant="contained" color="primary">
                                        Filter
                                    </Button>
                                </div>
                                <Box width={250}>
                                    <FormControlLabel
                                        control={<Switch checked={isCancelEnabled} onChange={handleToggleChange} sx={{ transform: 'scale(1.2)' }} />}
                                        label={isCancelEnabled ? "Cancel Ride Mode" : "Re-Assign Driver Mode"}
                                    />
                                    <FormControlLabel
                                        control={<Switch checked={isGetETAModeEnabled} onChange={handleETAModeToggleChange} sx={{ transform: 'scale(1.2)' }} />}
                                        label={isGetETAModeEnabled ? "Realtime ETA Mode" : "Calculated ETA Mode"}
                                    />
                                </Box>
                            </form>
                        </Paper>

                </Box>
                <Box className="dataGridContainer" style={{backgroundColor: theme.palette.background.paper}}>
                    <DataGrid
                        sx={{p:1}}
                        rows={rides}
                        columns={[
                            { field: 'id', headerName: 'Ride ID', width: 70 },
                            { field: 'userId', headerName: 'Cust. ID', width: 70 },
                            { field: 'customerName' , headerName: 'Cust. Name', width: 130 },
                            { field: 'customerPhone' , headerName: 'Cust. Phone', width:130 },
                            { field: 'driverPhone', headerName: 'Driver Phone', width: 130 },
                            { field: 'subscriptionId', headerName: 'Sub ID', width: 60 },
                            { field: 'pickupAddress', headerName: 'Pickup Address', width: 150 },
                            { field: 'driverName', headerName: 'Driver Name', width: 130 },
                            { field: 'dropAddress', headerName: 'Drop Address', width: 150 },
                            {
                                field: 'rideDateTime',
                                headerName: 'Ride Date Time',
                                width: 170,
                                valueGetter: (params) => {
                                    const [date,time] = params.value.split('T');
                                    const [year,month,day] = date.split('-');
                                    const [hour,minute] = time.split(':');
                                    return `${day}/${month}/${year} ${hour}:${minute}`;
                                },
                            },
                            {
                                field: 'driverETA',
                                headerName: 'ETA',
                                valueGetter: (params) => {
                                   // Assuming calculatedETA is a property of your row data
                                    const driverETA = params.row.driverETA; // Assuming driverETA is a property of your row data
                                    
                                    return formatTime(driverETA)
                                },
                            },

                            { field: 'rideStatus', headerName: 'Ride Status', width: 100 },
                            // { field: 'additionalRideDetails', headerName: 'Reschedule Status', width: 100 },
                            { field: 'cancel',
                                headerName: 'Action',
                                sortable: false,
                                width: 180,
                                renderCell: (params) => (
                                    <>
                                        {isCancelEnabled ? (
                                            <Button variant="contained" color="error" onClick={() => handleCancel(params.row.id)}>
                                                Cancel Ride
                                            </Button>
                                        ) : (
                                            <Button variant="contained" color="primary" onClick={() => handleDialogOpen(params.row.id)}>
                                                Reassign Driver
                                            </Button>
                                        )}
                                    </>
                                ),
                            },
                            { field: 'Google Map',
                                headerName: 'Google Map',
                                sortable: false,
                                width: 280,
                                renderCell: (params) => (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                const pickupLatitude = params.row.pickupLatitude;
                                                const pickupLongitude = params.row.pickupLongitude;
                                                const dropLatitude = params.row.dropLatitude;
                                                const dropLongitude = params.row.dropLongitude;
                                                const googleMapsUrl = `https://www.google.com/maps/dir/${pickupLatitude},${pickupLongitude}/${dropLatitude},${dropLongitude}`;
                                                window.open(googleMapsUrl, '_blank');
                                            }}
                                        >
                                            Map View
                                        </Button>
                                    </>
                                ),
                            },
                        ]}
                        pageSize={5}
                        getRowClassName={(params) => getRideStatusClassName(params.row.rideStatus)}
                        components={{
                            Toolbar: GridToolbarExport, // Add GridToolbarExport as the Toolbar component
                        }}
                    />
                    <ReassignDriverDialog rideId={selectedRideId} drivers={drivers} open={dialogOpen} onSubmit={handleSubmitReassignDriver} onClose={handleDialogClose} />
                </Box>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default UserRidesFiltered;
