// errorHandler.js
import authService from '../Services/AuthService';

const handleError = (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        authService.logout();
        window.location.href = '/login';
    }
};

export default handleError;