// SubscriptionDetailService.js
import api from "../Provider/api";
import handleError from "../Provider/errorHandler";

const getSubscriptions = async () => {
    try {
        const response = await api.get('subscription/v1/');
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

const getFilteredSubscriptions = async (userId) => {
    try {
        const response = await api.get(`subscription/v1/filter?userId=${userId}`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}
const updatePaymentStatus = async (id, paymentStatus) => {
    try {
        const response = await api.put('subscription/v1/update-payment-status', { id, paymentStatus });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

const subscriptionDetailService = {
    getSubscriptions,
    getFilteredSubscriptions,
    updatePaymentStatus // Add the new method to the exported object
};

export default subscriptionDetailService;