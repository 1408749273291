import React, { useState, useEffect } from 'react'; // Import useEffect
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Rating,
    Typography,
    useTheme
} from '@mui/material';
import TimePickerViewRenderers from '../../Components/TimePicker/Timepicker';

function HomePage() {
    const theme = useTheme();
    const label = "";
    const size = "80px";
    const [selectedTime, setSelectedTime] = useState(null);

    const handleTimeChange = (newTime) => {
        setSelectedTime(newTime);
    };

    useEffect(() => {
        console.log(selectedTime); // Log the selected time whenever it changes
    }, [selectedTime]); // Add selectedTime as a dependency

    return (
        <div style={{ backgroundColor: theme.palette.background.default }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Welcome to the Home Page!<br></br>
                Please Navigate to the User Rides page to view the rides.<br></br>
                <b>This page is for experimental tasks only</b>

            </Typography>
            <TimePickerViewRenderers label={label} size={size} onTimeChange={handleTimeChange} />
            <Button>
                Test Button
            </Button>
            <Button color="secondary">Secondary</Button>
            <Button variant="contained" color="success">
                Success
            </Button>
            <Button variant="outlined" color="error">
                Error
            </Button>

            <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                >
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                </RadioGroup>
            </FormControl>

            <Rating name="no-value" value={null} />
        </div>
    );
}

export default HomePage;