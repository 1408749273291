// driverService.js
import api from "../Provider/api";
import handleError from "../Provider/errorHandler";
import axios from "axios";
const DRIVER_API = process.env.REACT_APP_DRIVER_URL;

const getDrivers = async () => {
    try {
        const response = await api.get('driver/v1/');
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

const cancelRide = async(rideId) =>{
    try{
        const response=await axios.post(
            DRIVER_API+'cancelCustomerRide/',
            // '{\n    "customer_ride_ids": ["customer_ride_id1", "customer_ride_id2"]\n}',
            {
                'customer_ride_id': rideId
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

const updateRideDriver = async (rideId, driverId) => {
    try {
        const response = await axios.put(
            `${DRIVER_API}updateCustomerDriver/${rideId}/`,
            {
                'driver_id': driverId
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

// driverService.js
const getDriverByPhone = async (phone) => {
    try {
        const response = await api.get(`driver/v1/${phone}`);
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

// Add the new function to the exported object
const driverService = {
    getDrivers,
    cancelRide,
    updateRideDriver,
    getDriverByPhone // Add the new function here
};

export default driverService;
