import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import HomeComponent from '../Pages/Home/Home';
import RescheduledRides from "../Pages/RescheduledRides/RescheduledRides";
import UserRidesFiltered from "../Pages/UserRidesFiltered/UserRidesFiltered";
import Subscription from "../Pages/Subscription/Subscription";
import Customer from "../Pages/Customer/Customer";
import Report from "../Pages/Report/Report";
const routeConfig = [
    { path: '/', component: HomeComponent },
    { path: '/home', component: HomeComponent },
    {path: '/rescheduled-rides', component: RescheduledRides},
    {path: '/user-rides', component: UserRidesFiltered},
    {path: '/subscription', component: Subscription},
    {path: '/customer', component: Customer},
    {path: '/report', component: Report},

    // Add more routes here...
];

const ProtectedRoute = ({ children, ...rest }) => {
    const isLoggedIn  = JSON.parse(localStorage.getItem("isLoggedIn"));
    console.log(isLoggedIn)

    return (
        <Routes>
            {routeConfig.map(({ path, component }) => (
                <Route key={path} path={path} element={isLoggedIn ? React.createElement(component) : <Navigate to="/login" replace />} />
            ))}
        </Routes>
    );
};

export default ProtectedRoute;