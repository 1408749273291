import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import {Box, Typography, TextField, Paper, Button} from '@mui/material';
import CustomerService from '../../Services/CustomerService';
import './Customer.css';
import Swal from "sweetalert2";

function Customer() {
    const [customers, setCustomers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const theme = useTheme(); // Use the theme

    useEffect(() => {
        CustomerService.getCustomers().then(res => {
            if (res) {
                setCustomers(res.data);
            } else {
                console.error('No data returned from getAllCustomers');
            }
        }).catch(error => {
            console.error('Error fetching customers:', error);
        });
    }, []);

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Name', width: 130 },
        { field: 'phoneNumber', headerName: 'Phone Number', width: 130 },
        {
            field: 'active',
            headerName: 'Active Status',
            width: 100,
            renderCell: (params) => (
                params.value ? 'Active' : 'Inactive'
            )
        },
        { field: 'gender', headerName: 'Gender', width: 70 },
        {
            field: 'action',
            headerName: 'Action',
            width: 130,
            renderCell: (params) => (
                <Button variant="contained" color={params.row.active ? "error" : "primary"} onClick={() => toggleActiveStatus(params.row)}>
                    {params.row.active ? 'Deactivate' : 'Activate'}
                </Button>
            ),
        },
    ];

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        CustomerService.searchCustomers(event.target.value).then(res => {
            if (res) {
                setCustomers(res.data);
            } else {
                console.error('No data returned from searchCustomers');
                setCustomers([]);
            }
        }).catch(error => {
            if (error.response && error.response.status === 404) {
                setCustomers([]);
            }
            console.error('Error searching customers:', error);
        });
    };
    const toggleActiveStatus = async (customer) => {
        try {
            Swal.fire({
                title: 'Loading...',
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading();
                },
            });

            const updatedCustomer = await CustomerService.updateCustomer({ ...customer, active: !customer.active });
            Swal.close();

            setCustomers(customers.map(c => c.id === updatedCustomer.id ? updatedCustomer : c));

            // Show swal notification
            Swal.fire({
                title: 'Success!',
                text: updatedCustomer.active ? 'Customer activated successfully!' : 'Customer deactivated successfully!',
                icon: 'success',
                confirmButtonText: 'OK'
            });
        } catch (error) {
            Swal.close();
            console.error('Error updating customer active status:', error);

            // Show swal notification
            Swal.fire({
                title: 'Error!',
                text: 'Failed to update customer active status!',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    return (
        <div className="customer-container">

            <Box className="customers" style={{ backgroundColor: theme.palette.background.default }}>
                <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
                    Customer Data
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="center"  >
                    <Paper style={{width: '100%'}}>
                        <form className="form-container">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                autoComplete="off"
                                id="search"
                                label="Search Customers"
                                name="search"
                                placeholder="Enter ID/Name/Phone"
                                autoFocus
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </form>
                    </Paper>

                </Box>

                <Box className="dataGridContainer" style={{ backgroundColor: theme.palette.background.paper }}>
                    <DataGrid rows={customers} columns={columns} pageSize={5} />
                </Box>
            </Box>
        </div>
    );
}

export default Customer;