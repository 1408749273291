import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {Autocomplete, TextField} from "@mui/material";

const ReassignDriverDialog = ({ open, onClose,onSubmit, drivers,rideId }) => {
    const [selectedDriver, setSelectedDriver] = useState(null); // New state to hold the selected driver

    const handleSubmit = () => {
        onSubmit(selectedDriver ? selectedDriver.driverId : null,rideId); // Pass the selected driver id to onClose
        selectedDriver && setSelectedDriver(null); // Reset the selected driver
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Reassign Driver for Ride - {rideId}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{m:2}}>
                    To reassign a driver, please select the new Driver from the dropdown.
                </DialogContentText>
                <div className="">
                    <Autocomplete
                        placeholder={"Select Driver or Type to Search"}
                        sx={{m:2}}
                        options={drivers} // your options array
                        getOptionLabel={(option) => option.name + ' ID=' + option.driverId} // display the driver's name
                        onChange={(event, value) => setSelectedDriver(value)} // handle the selected driver
                        isOptionEqualToValue={(option, value) => option.driverId === value.driverId}
                        renderInput={(params) => <TextField {...params} label="Drivers" variant="outlined"/>}
                    />
                </div>


                {/* Add your form fields here */}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit} disabled={!selectedDriver}>Submit</Button>            </DialogActions>
        </Dialog>
    );
};

export default ReassignDriverDialog;