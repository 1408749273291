// report.jsx
import React, { useState } from 'react';
import rideDetailService from '../../Services/RideDetailService';

function Report() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const getRidesBetweenDates = async () => {
        try{
            const response = await rideDetailService.getRidesBetweenDates(startDate, endDate);
            const csvData = response.data;

            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = 'rides.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="Report" style={{padding: '50px'}}>
            <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
            <br></br>
            <br></br>

            <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
            <br></br>
            <br></br>

            <button onClick={getRidesBetweenDates}>Get Rides</button>
        </div>
    );
}

export default Report;